<template>
  <!-- <link rel="stylesheet" :href="'/css/index.css'" /> -->

  <a
    href="#"
    style="
      box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%),
        0 1px 10px 0 rgb(0 0 0 / 12%);
      display: flex;
      background: #eee;
    "
  >
    <div
      class="appHeader1 height add-box_shadow"
      style="width: 100%; background-color: rgb(238 238 238) !important"
    >
      <div class="text-left">
        <div class="logo-img">
          <img :src="logoimg" />
        </div>
        <div
          class="pageTitle"
          style="
            color: #616161;
            font-size: 20px;
            font-weight: 500;
          "
        >
          Open with an app
        </div>
      </div>
      <div class="download-icons" @click="getApkImage()">
        <!-- <a :href="apkImageData" download="ColorGame.apk" class="sub-link">
          <img :src="require('@/assets/images/download.png')" alt="" />
        </a> -->
        <a href="#" class="sub-link" @click.prevent="downloadApk">
       <img :src="require('@/assets/images/download.png')" alt="" />
       </a>
      </div>
    </div>
  </a>

  <div class="welcome-header">
    <h1>Welcome Back</h1>
    <h3>Quality Guarantee</h3>
  </div>

  <div id="appCapsule">
    <div
      id="carouselExampleSlidesOnly"
      class="carousel slide"
      data-ride="carousel"
    >
      <div class="carousel-inner">
        <swiper
          :pagination="{
            dynamicBullets: true,
          }"
          :modules="modules"
          class="mySwiper"
          :loop="true"
          :autoplay="{ delay: 2500, disableOnInteraction: false }"
        >
          <swiper-slide v-for="item in productData" :key="item.id">
            <div class="carousel-item active">
              <img class="d-block w-100" :src="item.material" alt="image" />
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <div class="sectionTitle mb-2">
      <div class="vcard mt-2">
        <div class="row">
          <div
            v-for="(item, index) in products"
            :key="index"
            :class="index % 2 == 0 ? 'col-6 pleft' : 'col-6 pright'"
          >
            <div class="vcard card text-center apply-img-center">
              <!-- <router-link 
              :to="{
                name: 'productDetails',
                query: { goodsId: item.id },
              }" v-show="pd">
                <a class="postItem">
                  <div class="imageWrapper">
                    <img :src="item.product_url" alt="image" class="image" />
                  </div>
                  <div class="text-center jewellery-name">{{ item.name }}</div>
                  <footer>₹ {{ item.price }}.00</footer>
                </a>
              </router-link> -->
              <!-- <router-link :to="'/login'" v-show="!pd"> -->
              <!-- <router-link :to="'/login'" > -->
              <a class="postItem">
                <div class="imageWrapper">
                  <img :src="item.product_url" alt="image" class="image" />
                </div>
                <div class="text-center jewellery-name">{{ item.name }}</div>
                <footer>₹ {{ item.price }}.00</footer>
              </a>
              <!-- </router-link> -->
            </div>
          </div>
        </div>
      </div>
      <div class="p-2"></div>
    </div>
  </div>
  <Footer />
  <PageReload />
</template>

<script>
// import { useMeta } from "vue-meta";
import Footer from "../components/CommonFooter.vue";
import { ref } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import PageReload from "../components/PageReload.vue";
import { Autoplay } from "swiper/modules";
import axios from "axios";
export default {
  rules: {
    "vue/multi-word-component-names": 0,
  },
  components: {
    Footer,
    Swiper,
    SwiperSlide,
    PageReload,
  },
  props: {
    toggleLoader: { type: Function },
  },
  setup() {
    // useMeta({ title: "Home Page" });
    const products = ref([]);
    const productData = ref([]);
    const apkImageData = ref([]);
    const logoimg = ref([]);
    const pd = ref(false);
    const modules = [Autoplay];
    const getProductData = async () => {
      axios
        .post("/get-all-product", {})
        .then((response) => {
          if (response.data.success === true) {
            products.value = response.data.data;
          } else {
            // alert(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    if (localStorage.getItem("authToken")) {
      pd.value = true;
    }
    const getProductData1 = async () => {
      axios
        .post("/get-banners")
        .then((response) => {
          if (response.data.success === true) {
            productData.value = response.data.data;
          } else {
            // alert(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    const getApkImage = async () => {
      await axios
        .post("/admin/admin-sitename-and-logoinformation")
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.data &&
            response.data.data.apk
          ) {
            apkImageData.value = response?.data?.data?.apk;
            logoimg.value = response?.data?.data?.logo;
          } else {
            console.error("Invalid response from server");
          }
        })
        .catch((error) => {
          console.error("Error fetching APK image:", error);
        });
    };
    const downloadApk = async () => {
      try {
        if (apkImageData.value) {
          const blob = await fetch(apkImageData.value).then(res => res.blob());
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'ColorGame.apk'); 
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        } else {
          console.error('APK image data not available');
        }
      } catch (error) {
        console.error('Error downloading APK:', error);
      }
    };
    
    getProductData1();
    getProductData();
    getApkImage();

    return {
      getApkImage,
      apkImageData,
      getProductData,
      products,
      productData,
      getProductData1,
      pd,
      modules,
      logoimg,
      downloadApk,
    };
  },
};
</script>
<!-- <style scoped>
@import "../assets/css/index.css";
</style> -->
