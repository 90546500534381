<template>
  <div>
    <Navbar />

    <!-- sidenav -->
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-2 col-md-3 col-sm-4 col-12 pl-0">
          <SaidManuBar />
        </div>
        <div
          class="alluser-content col-lg-10 col-md-9 col-sm-8 col-12 p-1 bg-white bg-gradient proper-table"
        >
          <h3 class="alluser-title p-1 ml-0 mr-0 m-1 add-bg-color-sub-header">
            All Users
          </h3>
          <nav class="navbar pr-0 pl-0">
            <div>
               Show 
               <select v-model="pagerow" @change="getAllUserInformation">
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
              </select>
               entries
            </div>
            <div class="alluser-search-box d-flex ml-auto" role="search">
              <label>Search:</label>
              <input
                class="form-control p-1"
                v-model="searchTerms"
                @input="search()"
                type="search"
                aria-label="Search"
                id="input"
              />
            </div>
          </nav>
          <div class="table-responsive table-responsive-sm">
            <table class="alluser-table table" v-if="loader === false">
              <thead>
                <tr>
                  <th scope="col" class="font-weight-bold text-center">
                    <span>Sr.no</span>
                  </th>
                  <th
                    scope="col"
                    class="font-weight-bold text-center add-table-data-in-center-width"
                  >
                    <i
                      class="bi bi-arrow-down-up mr-1 cursor-pointer"
                      @click="sort('mobile')"
                    ></i
                    >Phone
                  </th>
                  <th
                    scope="col"
                    class="font-weight-bold text-center add-table-data-in-center-width"
                  >
                    <i
                      class="bi bi-arrow-down-up mr-1 cursor-pointer"
                      @click="sort('code')"
                    ></i
                    >Referid
                  </th>
                  <th
                    scope="col"
                    class="font-weight-bold text-center add-table-data-in-center-width"
                  >
                    <i
                      class="bi bi-arrow-down-up mr-1 cursor-pointer"
                      @click="sort('owncode')"
                    ></i
                    >Referby
                  </th>
                  <th
                    scope="col"
                    class="font-weight-bold text-center add-table-data-in-center-width"
                  >
                    <i
                      class="bi bi-arrow-down-up mr-1 cursor-pointer"
                      @click="sort('password')"
                    ></i
                    >Password
                  </th>
                  <th
                    scope="col"
                    class="font-weight-bold text-center add-table-data-in-center-width"
                  >
                    <i
                      class="bi bi-arrow-down-up mr-1 cursor-pointer"
                      @click="sort('userwallet.totalUserWallet')"
                    ></i
                    >Wallet
                  </th>
                  <th
                    scope="col"
                    class="font-weight-bold text-center add-table-data-in-center-width"
                  >
                    <i
                      class="bi bi-arrow-down-up mr-1 cursor-pointer"
                      @click="sort('userfirstrecharge.amount')"
                    ></i
                    >First Recharge
                  </th>
                  <th
                    scope="col"
                    class="font-weight-bold text-center add-table-data-in-center-width"
                  >
                    <i
                      class="bi bi-arrow-down-up mr-1 cursor-pointer"
                      @click="sort('userrechargeamount.totalRechargeAmount')"
                    ></i
                    >Total Recharge
                  </th>
                  <th
                    scope="col"
                    class="font-weight-bold text-center add-table-data-in-center-width"
                  >
                    <i
                      class="bi bi-arrow-down-up mr-1 cursor-pointer"
                      @click="sort('userwithdrawals.userTotleWithdarawals')"
                    ></i
                    >Total Withdrawal
                  </th>
                  <th scope="col" class="font-weight-bold text-center">Bank</th>
                  <th scope="col" class="font-weight-bold text-center">
                    Action
                  </th>
                  <th
                    scope="col"
                    class="font-weight-bold text-center add-table-data-in-center-width"
                  >
                    <i
                      class="bi bi-arrow-down-up mr-1 cursor-pointer"
                      @click="sort('created_at')"
                    ></i
                    >Create Date
                  </th>
                </tr>
              </thead>
              <tbody v-if="allUserData?.length > 0">
                <tr v-for="(data, index) in allUserData" :key="index">
                  <td scope="col" class="text-center">{{ index + 1 }}</td>
                  <td scope="col" class="text-center">
                    {{ data?.mobile || 0 }}
                  </td>
                  <td scope="col" class="text-center">
                    {{ data?.owncode || 0 }}
                  </td>
                  <td scope="col" class="text-center">
                    {{ data?.code || " - " }}
                  </td>
                  <td scope="col" class="text-center">
                    {{ data.password || 0 }}
                  </td>
                  <td
                    scope="col"
                    class="text-center cursor-pointer"
                    @click="
                      (isEditWalletsModalVisible = true), openModal(data?._id)
                    "
                  >
                    {{ (data?.userwallet?.totalUserWallet || 0)?.toFixed(2) }}
                    <i class="bi bi-pencil-square cursor-pointer"></i>
                  </td>
                  <td scope="col" class="text-center">
                    {{ data?.userfirstrecharge?.amount || 0 }}
                  </td>
                  <td scope="col" class="text-center">
                    {{ data?.userrechargeamount?.totalRechargeAmount || 0 }}
                  </td>
                  <td scope="col" class="text-center">
                    {{ data?.userwithdrawals?.userTotleWithdarawals || 0 }}
                  </td>
                  <td scope="col" class="setcoustomewith">
                    <div>
                      <span class="font-weight-bold pr-1">UPI:</span>
                      {{
                        data?.userbankdetail?.upi === undefined ||
                        data?.userbankdetail?.upi === null ||
                        data?.userbankdetail?.upi === ""
                          ? " - "
                          : data?.userbankdetail?.upi
                      }}
                    </div>
                    <div>
                      <span class="font-weight-bold pr-1">Bank:</span>
                      {{
                        data?.userbankdetail?.bank_code
                          ? data?.userbankdetail?.bank_code?.toUpperCase()
                          : " - "
                      }}
                    </div>
                    <div>
                      <span class="font-weight-bold pr-1">AC No:</span>
                      {{ data?.userbankdetail?.bank_account || " - " }}
                    </div>
                    <div>
                      <span class="font-weight-bold pr-1">IFSC:</span>
                      {{ data?.userbankdetail?.ifsc_code || " - " }}
                    </div>
                    <div>
                      <span class="font-weight-bold pr-1">Name:</span>
                      {{ data?.userbankdetail?.name?.toUpperCase() || " - " }}
                    </div>
                  </td>
                  <td>
                    <div class="d-flex">
                      <div class="">
                        <router-link
                          :to="`/admin/adminusergamehistory/${data?._id}`"
                        >
                          <button
                            class="btn btn-success action-alluser-btn font-weight-bold"
                          >
                            Play History
                          </button>
                        </router-link>
                      </div>
                      <div class="ml-1">
                        <button
                          v-bind:class="
                            data?.status === true
                              ? 'btn btn-success action-alluser-btn font-weight-bold'
                              : 'btn btn-danger action-alluser-btn font-weight-bold'
                          "
                          v-on:click="adminUpdateActive(data?._id)"
                        >
                          {{ data?.status === true ? "Active" : "Block" }}
                        </button>
                      </div>
                    </div>

                    <div class="d-flex">
                      <div class="mt-1">
                        <button
                          class="btn btn-warning action-alluser-btn font-weight-bold"
                          v-on:click="
                            (isEditUserModalVisible = true),
                              showModal(data?._id)
                          "
                        >
                          Edit
                        </button>
                      </div>
                      <div class="mt-1 ml-1">
                        <router-link
                          :to="`/admin/adminreferidallusers/${data?.owncode}`"
                        >
                          <button
                            class="btn btn-secondary action-alluser-btn font-weight-bold"
                          >
                            Refral User
                          </button>
                        </router-link>
                      </div>
                    </div>

                    <div class="mt-1">
                      <button
                        v-bind:class="
                          data?.play === false
                            ? 'btn btn-success action-alluser-btn font-weight-bold'
                            : 'btn btn-danger action-alluser-btn font-weight-bold'
                        "
                        v-on:click="adminUpdatePlay(data?._id)"
                      >
                        {{ data?.play === false ? "Play" : "UnPlay" }}
                      </button>
                    </div>
                  </td>
                  <td scope="col" class="text-center">
                    {{ new Date(data?.created_at).toLocaleDateString() }}
                  </td>
                </tr>
                <div class="notification-modal">
                  <Modal
                    v-model:visible="isEditWalletsModalVisible"
                    height="auto"
                    width="40%"
                    :maskClosable="false"
                    offsetTop="25%"
                  >
                    <div style="background: #fff" class="modal-header">
                      <h5
                        class="modal-title"
                        id="exampleModalLabel"
                        style="color: #000; font-size: 25px"
                      >
                        Change Amount
                      </h5>
                    </div>
                    <div class="modal-body">
                      <div class="sign-info logout-info">
                        <p style="color: #000">
                          Mobile: {{ formData?.mobile }}
                        </p>
                      </div>

                      <div>
                        <h6 class="mt-4 font-weigth-bold">Amount</h6>
                        <input
                          type="number"
                          class="w-100"
                          required
                          v-model="formData.amount"
                        />
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-comman active"
                        data-dismiss="modal"
                        v-on:click="
                          (isEditWalletsModalVisible = false), closeModal()
                        "
                      >
                        Close
                      </button>
                      <button
                        class="btn btn-comman active"
                        v-on:click="
                          (isEditWalletsModalVisible = false),
                            closeModal(),
                            adminUpdateWallets()
                        "
                      >
                        Edit
                      </button>
                    </div>
                  </Modal>
                </div>
                <div class="notification-modal">
                  <Modal
                    v-model:visible="isEditUserModalVisible"
                    height="auto"
                    width="40%"
                    :maskClosable="false"
                    offsetTop="25%"
                  >
                    <div style="background: #fff" class="modal-header">
                      <h5
                        class="modal-title"
                        id="exampleModalLabel"
                        style="color: #000; font-size: 25px"
                      >
                        Edit User
                      </h5>
                    </div>
                    <div class="modal-body">
                      <form class="mx-auto mt-5">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label for="upiid" class="font-weight-bold h5"
                                >Mobile Number</label
                              >
                              <input
                                type="number"
                                class="form-control border px-2 py-1"
                                id="mobile"
                                v-model="usersFormData.mobile"
                                required
                              />
                            </div>
                            <div class="form-group">
                              <label
                                for="minimumrecharge"
                                class="font-weight-bold h5"
                                >Email</label
                              >
                              <input
                                type="email"
                                class="form-control border px-2 py-1"
                                id="email"
                                v-model="usersFormData.email"
                                required
                              />
                            </div>
                            <div class="form-group">
                              <label
                                for="minimumwithdrawal"
                                class="font-weight-bold h5"
                                >Password</label
                              >
                              <input
                                type="text"
                                class="form-control border px-2 py-1"
                                id="password"
                                v-model="usersFormData.password"
                                required
                              />
                            </div>
                            <div class="form-group">
                              <label
                                for="commission"
                                class="font-weight-bold h5"
                                >Status</label
                              >

                              <select
                                v-model="usersFormData.status"
                                class="form-select px-2 py-1 form-control border"
                              >
                                <option :value="true">True</option>
                                <option :value="false">False</option>
                              </select>
                            </div>
                            <div class="form-group">
                              <label for="name" class="font-weight-bold h5"
                                >Name</label
                              >
                              <input
                                type="text"
                                class="form-control border px-2 py-1"
                                id="name"
                                v-model="usersFormData.name"
                                required
                              />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label for="ifsc_code" class="font-weight-bold h5"
                                >IFSC</label
                              >
                              <input
                                type="text"
                                class="form-control border px-2 py-1"
                                id="ifsc_code"
                                v-model="usersFormData.ifsc_code"
                                required
                              />
                            </div>
                            <div class="form-group">
                              <label for="bank_code" class="font-weight-bold h5"
                                >Bank Name</label
                              >
                              <input
                                type="text"
                                class="form-control border px-2 py-1"
                                id="bank_code"
                                v-model="usersFormData.bank_code"
                                required
                              />
                            </div>
                            <div class="form-group">
                              <label
                                for="bank_account"
                                class="font-weight-bold h5"
                                >Account Number</label
                              >
                              <input
                                type="text"
                                class="form-control border px-2 py-1"
                                id="bank_account"
                                v-model="usersFormData.bank_account"
                                required
                              />
                            </div>
                            <div class="form-group">
                              <label for="upi" class="font-weight-bold h5"
                                >UPI Address</label
                              >
                              <input
                                type="text"
                                class="form-control border px-2 py-1"
                                id="upi"
                                v-model="usersFormData.upi"
                              />
                            </div>
                            <div class="form-group">
                              <label for="Type" class="font-weight-bold h5"
                                >Type</label
                              >
                              <select
                                v-model="usersFormData.type"
                                class="form-select px-2 py-1 form-control border"
                              >
                                <option value="BANK">Bank</option>
                                <option value="UPI">UPI</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-comman active"
                        data-dismiss="modal"
                        v-on:click="
                          (isEditUserModalVisible = false), hideModal()
                        "
                      >
                        Close
                      </button>
                      <button
                        class="btn btn-comman active"
                        type="submit"
                        v-on:click="
                          (isEditUserModalVisible = false),
                            hideModal(),
                            SubmitData()
                        "
                      >
                        Edit
                      </button>
                    </div>
                  </Modal>
                </div>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="12" class="text-center">No Data Found</td>
                </tr>
              </tbody>
            </table>
          </div>
          <nav aria-label="Page navigation" style="display: flex;">
            <span class="justify-content-start" style="padding-left: 10px">Showing 1 to {{pagerow}} of {{totalCount}} Entries</span>
            <ul class="alluser-pagination pagination justify-content-end" style="padding-left: 60%">
              <li class="page-item" :class="{ disabled: currentPage === 1 }">
                <button class="page-link" @click="prevPage">Previous</button>
              </li>
              <li class="page-item">
                <button
                  class="page-link"
                  @click="gotoPage(currentPage - 1)"
                  v-if="currentPage > 1"
                >
                  {{ currentPage - 1 }}
                </button>
              </li>
              <li
                class="page-item active"
                v-if="currentPage === 1 || currentPage !== totalPages"
              >
                <button class="page-link" @click="gotoPage(currentPage)">
                  {{ currentPage }}
                </button>
              </li>
              <li class="page-item">
                <button
                  class="page-link"
                  @click="gotoPage(currentPage + 1)"
                  v-if="
                    currentPage < totalPages && currentPage + 1 !== totalPages
                  "
                >
                  {{ currentPage + 1 }}
                </button>
              </li>
              <li class="page-item">
                <button class="page-link" v-if="totalPages > 2">....</button>
              </li>
              <li
                class="page-item"
                :class="currentPage === totalPages && 'active'"
              >
                <button
                  class="page-link"
                  @click="gotoPage(totalPages)"
                  v-if="totalPages > 1"
                >
                  {{ totalPages }}
                </button>
              </li>
              <li
                class="page-item"
                :class="{ disabled: currentPage === totalPages }"
              >
                <button class="page-link" @click="nextPage">Next</button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
  <!-- loader -->
  <div class="loadings" v-show="loader" style="/* display: none; */">
    <div
      class="v-dialog v-dialog--persistent"
      style="width: 300px; display: block"
    >
      <div class="v-card v-sheet theme--dark teals-loadings">
        <div class="v-card__text">
          <span>Loading</span>
          <div
            role="progressbar"
            aria-valuemin="0"
            aria-valuemax="100"
            class="v-progress-linear mb-0"
            style="height: 7px"
          >
            <div
              class="v-progress-linear__background white"
              style="height: 7px; opacity: 0.3; width: 100%"
            ></div>
            <div class="v-progress-linear__bar">
              <div
                class="v-progress-linear__bar__indeterminate v-progress-linear__bar__indeterminate--active"
              >
                <div
                  class="v-progress-linear__bar__indeterminate long white"
                ></div>
                <div
                  class="v-progress-linear__bar__indeterminate short white"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Navbar from "./Navbar.vue";
import SaidManuBar from "./SideMenuBar.vue";
import { Modal } from "usemodal-vue3";
export default {
  name: "AllUsers",

  components: {
    Navbar,
    SaidManuBar,
    Modal,
  },
  data() {
    return {
      allUserData: [],
      loader: false,
      searchTerms: null,
      isEditWalletsModalVisible: false,
      isEditUserModalVisible: false,
      formData: {
        userid: "",
        amount: null,
        mobile: null,
      },
      usersFormData: {
        _id: "",
        userid: "",
        mobile: null,
        password: "",
        email: "",
        status: true,
        name: "",
        ifsc_code: "",
        bank_code: "",
        bank_account: null,
        upi: null,
        type: "",
      },
      currentPage: 1,
      totalPages: 0,
      pagerow: 10,
      sortBy: null,
      sortOrder: 1,
      totalCount:0,
    };
  },
  methods: {
    async getAllUserInformation() {
      try {
        this.loader = true;
        const response = await axios.post("/admin/adminalluser", {
          page: this.currentPage,
          pageSize: Number(this.pagerow),
        });
        this.allUserData = response?.data?.data;
        this.totalPages = response?.data?.totalPages;
        this.totalCount = response?.data?.totalCount;
        this.loader = false;
      } catch (error) {
        console.log("Error", error);
      }
    },
    async search() {
      try {
        if (this.searchTerms) {
          const response = await axios.post(`/admin/adminalluser`, {
            searchTerms: this.searchTerms,
          });
          this.allUserData = response.data.data;
        } else {
          this.getAllUserInformation();
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async SubmitData() {
      let usersFormData = new FormData();
      usersFormData.append("userid", usersFormData._id);
      usersFormData.append("userid", usersFormData.userid);
      usersFormData.append("mobile", usersFormData.mobile);
      usersFormData.append("password", usersFormData.password);
      usersFormData.append("email", usersFormData.email);
      usersFormData.append("status", usersFormData.status);
      usersFormData.append("name", usersFormData.name);
      usersFormData.append("ifsc_code", usersFormData.ifsc_code);
      usersFormData.append("bank_code", usersFormData.bank_code);
      usersFormData.append("bank_account", usersFormData.bank_account);
      usersFormData.append("upi", usersFormData.upi);
      usersFormData.append("type", usersFormData.type);
      await axios
        .post("/admin/adminupdatealluser", this.usersFormData)
        .then((response) => {
          if (response) {
            this.getAllUserInformation();
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    },

    async adminUpdateActive(_id) {
      await axios
        .put("/admin/adminactiveunactiveuser", {
          _id,
        })
        .then((response) => {
          if (response) {
            this.getAllUserInformation();
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    },

    async adminUpdatePlay(_id) {
      await axios
        .put("/admin/adminplay", { _id })
        .then((response) => {
          if (response) {
            this.getAllUserInformation();
          }
        })
        .catch((error) => {
          console.log("Error", error);
        });
    },

    async adminUpdateWallets() {
      let formData = new FormData();
      formData.append("userid", formData.userid);
      formData.append("amount", formData.amount);

      await axios
        .put("/admin/adminupdatewallet", this.formData)
        .then((responses) => {
          if (responses) {
            this.getAllUserInformation();
          }
        })
        .catch((error) => {
          console.log("Error", error);
        });
    },
    async openModal(_id) {
      let walletsData = await this.allUserData.find((item) => item._id === _id);

      if (walletsData) {
        this.formData = {
          userid: walletsData?._id,
          amount: walletsData?.userwallet?.totalUserWallet,
          mobile: walletsData?.mobile,
        };
      }
      this.isEditWalletsModalVisible = true;
      document.body.classList.add("modal-open");
    },
    closeModal() {
      this.isEditWalletsModalVisible = false;
      document.body.classList.remove("modal-open");
    },
    async showModal(_id) {
      let walletsData = await this.allUserData.find((item) => item._id === _id);

      if (walletsData) {
        this.usersFormData = {
          _id: walletsData?._id,
          userid: walletsData?.userid,
          mobile: walletsData?.mobile,
          password: walletsData?.password,
          email: walletsData?.email,
          status: walletsData?.status,
          name: walletsData?.userbankdetail?.name,
          ifsc_code: walletsData?.userbankdetail?.ifsc_code,
          bank_code: walletsData?.userbankdetail?.bank_code,
          bank_account: walletsData?.userbankdetail?.bank_account,
          upi: walletsData?.userbankdetail?.upi,
          type: walletsData?.userbankdetail?.type,
        };
      }
      this.isEditUserModalVisible = true;
      document.body.classList.add("modal-open");
    },
    hideModal() {
      this.isEditUserModalVisible = false;
      document.body.classList.remove("modal-open");
    },

    async UserGameHistory(userId) {
      const response = await axios.post("/admin/user_game_history", {
        userId: userId,
      });
      this.usergamehistory = response.data.betting_history;
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.getAllUserInformation();
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.getAllUserInformation();
      }
    },
    gotoPage(page) {
      this.currentPage = page;
      this.getAllUserInformation();
    },

    sort(field) {
      if (this.sortBy === field) {
        this.sortOrder *= -1;
      } else {
        this.sortBy = field;
        this.sortOrder = 1;
      }
      this.allUserData.sort((a, b) => {
        const valueA = this.getNestedPropertyValue(a, field);
        const valueB = this.getNestedPropertyValue(b, field);
        if (valueA < valueB) {
          return -1 * this.sortOrder;
        }
        if (valueA > valueB) {
          return 1 * this.sortOrder;
        }
        return 0;
      });
    },
    getNestedPropertyValue(obj, path) {
      const properties = path.split(".");
      let value = obj;
      for (const prop of properties) {
        value = value[prop];
        if (value === undefined) {
          return "";
        }
      }
      return value;
    },
  },

  mounted() {
    this.getAllUserInformation();
    const user = localStorage.getItem("user-info");
    if (!user) {
      this.$router.push({ name: "AdminLogin" });
    }
  },
};
</script>
